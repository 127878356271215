import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';


const imgAssetPattern = '^https://www.datocms-assets.com/.*.(jpg|png)$';

const HelmetMichelberger = ({ seo, favicon, children, ...rest }) => React.createElement(
  Helmet,
  rest,
  (seo ? seo.tags : [])
    .concat(favicon ? favicon.tags : [])
    .map((item, i) => React.createElement(
      item.tagName,
      {
        key: `helmet-datocms-${i}`, // eslint-disable-line react/no-array-index-key
        ...Object.entries(item.attributes || {})
          .reduce((acc, [name, value]) => {
            if (value) {
              let val = value;
              if (RegExp(imgAssetPattern).test(value)) {
                val += '?w=1200';
              }
              acc[name] = val;
            }
            return acc;
          }, {}),
      },
      item.content,
    )).concat(children),
);

HelmetMichelberger.propTypes = {
  children: PropTypes.any,
  favicon: PropTypes.shape({
    tags: PropTypes.array,
  }),
  seo: PropTypes.shape({
    tags: PropTypes.array,
  }),
};

export default HelmetMichelberger;
