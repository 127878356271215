module.exports = {
  defaultLangKey: 'en',
  locales: ['en', 'de'],
  localeNameMap: {
    en: {
      en: 'English',
      de: 'Englisch',
    },
    de: {
      en: 'German',
      de: 'Deutsch',
    },
  },
  siteTitle: 'Michelberger',
  siteUrl: 'https://www.michelbergerhotel.com',
};
