// From gatsby-starter-default-i18n
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import HelmetMichelberger from '../components/HelmetMichelberger';

import { defaultLangKey, locales } from '../data/metadata';
import { getUserLangKey } from '../helpers/i18n';


class RedirectIndex extends React.PureComponent {
  constructor(args) {
    super(args);
    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const langKey = getUserLangKey(locales, defaultLangKey);
      const homeUrl = `/${langKey}/${window.location.search}`;

      window.location.replace(homeUrl);
    }
  }

  render() {
    const { data: { allDatoCmsHome } } = this.props;

    const home = allDatoCmsHome.edges[0].node;

    return (
      <React.Fragment>
        <HelmetMichelberger seo={home.seoMetaTags} />
        <div />
      </React.Fragment>
    );
  }
}

RedirectIndex.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RedirectIndex;

export const query = graphql`
  query RedirectIndex {
    allDatoCmsHome(filter: { locale: { eq: "en" } }) {
      edges {
        node {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
  }
`;
